var $document = $(document);
var jumpPosition = Math.floor((Math.random() * 150) + 1) - 50;

$(document).ready(function(){
  
  // ------ NAVIGATION
  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

// ------ MASONRY
  var $grid = $('.masonry').imagesLoaded( function() {
      // init Masonry after all images have loaded
      $grid.masonry({
          // set itemSelector so .grid-sizer is not used in layout
          itemSelector: '.masonry-item ',
          // use element for option
          columnWidth: '.masonry-sizer',
          percentPosition: true
      });
  });

});

